import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useUser } from '../lib/user';

const Button = dynamic(() => import('../components/Button'), {
  ssr: false,
});

function Error() {
  const user = useUser().state.user;

  return (
    <>
      <NextSeo
        title={`404 - Page Not Found | ${process.env.NEXT_PUBLIC_APP_NAME}`}
        canonical={`${process.env.NEXT_PUBLIC_DOMAIN}404`}
        description="Sorry, but the page you are looking for can't be found and is gone permanently. It's most likely it's been removed from our website because the information it contains was out of date or no longer relevant."
      />
      <section className="feed section item">
        <section className="w-full h-full max-w-screen-sm mx-auto">
          <section className="space-y-4  text-gray-700">
            <section>
              <h1 className="text-6xl font-bold text-red-900">404</h1>
              <p className="text-xl">This page is no longer available</p>
            </section>
            <p className="text-xl">
              Sorry, but the page you are looking for can't be found and is gone permanently. It's
              most likely it's been removed from our website because the information it contains was
              out of date or no longer relevant.
            </p>
            <Button as="a" href={user.id ? '/discover' : ''} variant="outlined">
              Continue
            </Button>
          </section>
        </section>
      </section>
    </>
  );
}

export default Error;
